import React, { useState, useEffect } from 'react';
import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={'https://www.tacobueno.com/assets/food/tacos/Taco_Crispy_Beef_990x725.jpg'} className="App-logo" alt="logo" />
//       </header>
//     </div>
//   );
// }

// export default App;

function App() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition({ 
        x: Math.floor(Math.random() * (window.innerWidth - 100)),
        y: Math.floor(Math.random() * (window.innerHeight - 100)),
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const [target, setTarget] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTarget({ 
        x: Math.floor(Math.random() * (window.innerWidth - 100)),
        y: Math.floor(Math.random() * (window.innerHeight - 100)),
      });
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const handleMouseMove = (event) => {
    setPosition({ 
      x: event.clientX - 50, // Subtract 50 to center the image
      y: event.clientY - 50,
    });
  };

  return (
    <div 
      style={{ height: '100vh', width:'100%', cursor: 'grab', backgroundColor:'red' }}
      // onMouseMove={handleMouseMove}
    >
      <img 
        src="https://www.tacobueno.com/assets/food/tacos/Taco_Crispy_Beef_990x725.jpg" 
        alt="taco"
        style={{ 
          position: 'absolute',
          top: `${position.y}px`,
          left: `${position.x}px`,
          cursor: 'grabbing',
          transition: 'transform 2s',
          transform: `translate(${target.x - position.x}px, ${target.y - position.y}px)`,
        }}
        className="App-logo"
      />
    </div>
  );
}

export default App;
